import dayjs from 'dayjs';
import {REGEX} from '@/constants';
export const formatDateTime = (dateTime: string) => {
  const isValidDateTime = dayjs(dateTime).isValid();
  if (isValidDateTime) {
    return dayjs(dateTime).format('DD/MM/YYYY HH:mm')
  } else return ''
}

export const formatLongString = (string: string, maxVisibleLength = 40) => {
  const convertString =  string;
  if (convertString && convertString.length > maxVisibleLength) {
    const cutString = convertString.substring(0, maxVisibleLength);
    return `${cutString}...`
  } else return convertString;
}

export const checkRequiredField = (fieldData: any) => {
  if (!fieldData || typeof fieldData === 'string' && !fieldData.trim()) {
    return 'This field cannot be empty.'
  } else return ''
}

export const checkMinLength = (fieldData: string, minLength: number) => {
  let errorMessage = checkRequiredField(fieldData);
  
  if (!errorMessage && fieldData.length < minLength) {
    errorMessage = `This field must be greater than ${minLength} characters`
  }
  return errorMessage;
}

export const checkValidateEmail = (fieldData: string) => {
  let errorMessage = checkRequiredField(fieldData);

  if (!errorMessage && !REGEX.EMAIL_VALIDATE.test(fieldData)) {
    errorMessage = 'Invalid email address';
  }
    
  return errorMessage;
};

export const checkValidatePriority = (fieldData: string) => {
  let errorMessage = checkRequiredField(fieldData);

  if (!errorMessage && fieldData === '0') {
    errorMessage = 'Priority must be greater than 0';
  }

  return errorMessage;
}

export const convertDataFilterTags = (values: any, dataFilters: any) => {
  return values.map((config: any) => {
    if (config.type === 'range') {
      config.value = dataFilters[`${config.key}From`] >= 0 && dataFilters[`${config.key}To`] ? `From ${dataFilters[`${config.key}From`]} To ${dataFilters[`${config.key}To`]}` : dataFilters[`${config.key}From`] && !dataFilters[`${config.key}To`] ? `From ${dataFilters[`${config.key}From`]}` : !dataFilters[`${config.key}From`] && dataFilters[`${config.key}To`] ? `To ${dataFilters[`${config.key}To`]}` : ''
    }
    else if (config.type === 'range-date') {
      config.value = dataFilters[`${config.key}From`] && dataFilters[`${config.key}To`] ? `From ${dayjs(dataFilters[`${config.key}From`]).format('DD/MM/YYYY')} To ${dayjs(dataFilters[`${config.key}To`]).format('DD/MM/YYYY')}` : dataFilters[`${config.key}From`] && !dataFilters[`${config.key}To`] ? `From ${dayjs(dataFilters[`${config.key}From`]).format('DD/MM/YYYY')}` : !dataFilters[`${config.key}From`] && dataFilters[`${config.key}To`] ? `To ${dayjs(dataFilters[`${config.key}To`]).format('DD/MM/YYYY')}` : ''
    }
    else if (config.type === 'date') {
      config.value = `${dayjs(dataFilters[`${config.key}`]).format('DD/MM/YYYY')}`
    } else if (config.type === 'checkbox' && config.key === 'statuses') {
      config.value = dataFilters[`${config.key}`] ?dataFilters[`${config.key}`].map((status: any) => {
        if (status == 0) status = 'Inactive'
        else status = 'Active'
        return status
      }) : [];
      if (config.value.length === 0) {
        config.value = ''
      }
    } else {
      config.value = dataFilters[config.key];
    }
    return config
  }).filter((item: any) => item.value)
}

export const removeDataFilterTags = (filterDatas: any, type: string, key: string) => {
  const newObject = Object.assign(filterDatas, {})
  if (type === 'range' || type === 'range-date') {
    delete filterDatas[`${key}From`]
    delete filterDatas[`${key}To`]
  } else {
    delete filterDatas[key]
  }
  return newObject;
}

export const exitArrs = (arr1: any, arr2: any) => {
  return arr1.filter((data: any) => {
    return arr2.includes(data.id);
  });
}

export const upperCaseFirstCharacter = (str: string) =>{
  if (str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
  } else return '';
}
